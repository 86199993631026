import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const SOCKET_URL = 'https://api.machi-kunzult.com';
// const SOCKET_URL = 'http://localhost:3000'; // Replace with server URL

const App = () => {
  const userId = 'd07e03ed-108b-477a-8a2a-76d163717e0f';
  const facilityId = '604d714c-5dc2-45d4-97c6-6d71f771aa71';
  const [notifications, setNotification] = useState([]);

  useEffect(() => {
    const socket = io(SOCKET_URL, {auth: {
      // Pass the user_id and health_provider_id
      withCredentials: true,
      token: {userId, facilityId}
    }});

    // socket.connect()
    socket.on('connect', (item) => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

     // Every user will need to join thier personal room
    socket.emit('joinPersonalRoom', userId);

     // This is exclusively for general roles like staff, admins, maybe nurse.
    socket.emit('joinFacilityRoom', facilityId);

    socket.on('notification', (data)=>{
        setNotification((prev) => [...prev, data.notifications])
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      {
        notifications.map((notification)=>(
          <p>{notification}</p>
        ))
      }

      <p>Socket.IO Example</p>
    </div>
  );
};

export default App;
